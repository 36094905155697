import { Action } from 'redux';

// talk to expert pop-up actions
export const OPEN_POPUP_TALK_TO_EXPERT = 'OPEN_POPUP_TALK_TO_EXPERT';
export const CLOSE_POPUP_TALK_TO_EXPERT = 'CLOSE_POPUP_TALK_TO_EXPERT';

// open request callback pop-up though talk to expert
export const OPEN_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK =
    'OPEN_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK';
export const CLOSE_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK =
    'CLOSE_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK';

// request callback pop-up actions
export const OPEN_POPUP_REQUEST_CALLBACK = 'OPEN_POPUP_REQUEST_CALLBACK';
export const CLOSE_POPUP_REQUEST_CALLBACK = 'CLOSE_POPUP_REQUEST_CALLBACK';

// need help pop-up ( toast ) actions
export const OPEN_POPUP_NEED_HELP = 'OPEN_POPUP_NEED_HELP';
export const CLOSE_POPUP_NEED_HELP = 'CLOSE_POPUP_NEED_HELP';

// show more details pop-up actions
export const OPEN_POPUP_SHOW_MORE_DETAILS = 'OPEN_POPUP_SHOW_MORE_DETAILS';
export const CLOSE_POPUP_SHOW_MORE_DETAILS = 'CLOSE_POPUP_SHOW_MORE_DETAILS';

// get help pop-up actions
export const OPEN_POPUP_GET_HELP = 'OPEN_POPUP_GET_HELP';
export const CLOSE_POPUP_GET_HELP = 'CLOSE_POPUP_GET_HELP';

// understand why pop-up actions
export const OPEN_POPUP_UNDERSTAND_WHY = 'OPEN_POPUP_UNDERSTAND_WHY';
export const CLOSE_POPUP_UNDERSTAND_WHY = 'CLOSE_POPUP_UNDERSTAND_WHY';

// dont have this details pop-up
export const OPEN_POPUP_DO_NOT_HAVE_THIS_DETAILS = 'OPEN_POPUP_DO_NOT_HAVE_THIS_DETAILS';
export const CLOSE_POPUP_DO_NOT_HAVE_THIS_DETAILS = 'CLOSE_POPUP_DO_NOT_HAVE_THIS_DETAILS';

// dispatch and handlings for all the pop-ups

/**
 * Action creator to open the talk to expert pop-up.
 *
 */
export const openPopupTalkToExpert = (specialRequirement: boolean = false) => ({
    // Identify the action type
    type: OPEN_POPUP_TALK_TO_EXPERT,
    payload: specialRequirement,
});

/**
 * Action creator to close the talk to expert pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupTalkToExpert = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_TALK_TO_EXPERT,
});

/**
 * Action creator to open the talk to expert request callback pop-up.
 *
 * @returns {Action} The action object.
 */
export const openPopupTalkToExpertRequestCallback = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK,
});

/**
 * Action creator to close the talk to expert request callback pop-up.
 *
 * @param {boolean} reOpenTalkToExport - Whether to reopen the talk to expert pop-up after closing the request callback pop-up.
 */
export const closePopupTalkToExpertRequestCallback = (
    reOpenTalkToExport: boolean = false,
) => ({
    // Identify the action type
    type: CLOSE_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK,
    // Payload containing the boolean value for reopening the talk to expert pop-up
    payload: reOpenTalkToExport,
});

/**
 * Action creator to open the request callback pop-up.
 *
 * @returns {Action} The action object.
 */
export const openPopupRequestCallback = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_REQUEST_CALLBACK,
});

/**
 * Action creator to close the request callback pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupRequestCallback = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_REQUEST_CALLBACK,
    // No payload is required as this action simply closes the pop-up
});

/**
 * Action creator to open the need help pop-up.
 *
 */
export const openPopupNeedHelp = (id: string | number) => ({
    // Identify the action type
    type: OPEN_POPUP_NEED_HELP,
    payload: id,
});

/**
 * Action creator to close the need help pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupNeedHelp = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_NEED_HELP,
    // No payload is required as this action simply closes the pop-up
});

/**
 * Action creator to open the show more details pop-up.
 *
 * @returns {Action} The action object.
 */
export const openPopupShowMoreDetails = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_SHOW_MORE_DETAILS,
});

/**
 * Action creator to close the show more details pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupShowMoreDetails = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_SHOW_MORE_DETAILS,
    // No payload is required as this action simply closes the pop-up
});

/**
 * Action creator to open the get help pop-up.
 *
 * @returns {Action} The action object.
 */
export const openPopupGetHelp = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_GET_HELP,
});

/**
 * Action creator to close the get help pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupGetHelp = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_GET_HELP,
    // No payload is required as this action simply closes the pop-up
});

/**
 * Action creator to open the understand why pop-up.
 *
 * @returns {Action} The action object.
 */
export const openPopupUnderstandWhy = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_UNDERSTAND_WHY,
});

/**
 * Action creator to close the understand why pop-up.
 *
 * @returns {Action} The action object.
 */
export const closePopupUnderstandWhy = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_UNDERSTAND_WHY,
    // No payload is required as this action simply closes the pop-up
});

/**
 * Action creator to open the don't have details pop-up.
 *
 * @returns {Action} The action object.
 */
export const openDoNotHaveDetails = (): Action => ({
    // Identify the action type
    type: OPEN_POPUP_DO_NOT_HAVE_THIS_DETAILS,
});

/**
 * Action creator to close the don't have details pop-up.
 *
 * @returns {Action} The action object.
 */
export const closeDoNotHaveDetails = (): Action => ({
    // Identify the action type
    type: CLOSE_POPUP_DO_NOT_HAVE_THIS_DETAILS,
    // No payload is required as this action simply closes the pop-up
});