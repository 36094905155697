import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import style from './style.module.scss';
import xcircle from '../../assets/images/x-circle.svg';
import logo from '../../assets/images/logo.png';
import { CheckCircleFill, Download, Globe } from 'react-bootstrap-icons';
// import useMSG90OTPWidget from '../QuoteLayout/MSG90widgetfunction';
import { useLayoutEffect, useState } from 'react';
import SMELogin from '../../components/SMEFireInsurance/SMELoginModal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../utils/types';
import { Dropdown } from 'react-bootstrap';
import { ChangeLanguageAction } from '../../Redux/Actions/PreferenceActions';
import { ELanguagesOptions } from '../../Redux/Reducers/PreferenceReducer';
import { openPopupTalkToExpert } from '../../Redux/Actions/PopupActions';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header = () => {
    // const { handleSubmit } = useMSG90OTPWidget();

    const [selectedLanguage, setSelectedLanguage] = useState('english');
    const languages = [
        {
            name: 'english',
            display: 'English',
        },
        {
            name: 'hindi',
            display: 'हिंदी',
        }
    ];

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { t: translate } = useTranslation();

    const [continuePayment] = useState(false);
    const [failedPayment] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const Preference = useSelector((state: IReduxState) => state.Preference);

    const { currentStep } = useSelector(
        (state: IReduxState) => state.SMEDetails,
    );

    const handleLanguageSelect = (language: any) => {
        setSelectedLanguage(language);
        dispatch(ChangeLanguageAction(language as keyof ELanguagesOptions));
    };

    useLayoutEffect(() => {
        setSelectedLanguage(Preference?.language);
    }, [Preference?.language]);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className={style.headerSection}>
                <Navbar
                    expand="lg"
                    className={`bg-body-tertiary  ${style.navNavbar}`}
                >
                    <Container>
                        <Navbar.Brand
                            href={
                                currentStep > 13 ||
                                ![
                                    '/buy-fire-insurance',
                                    '/buy-stock-insurance',
                                    '/sme-onboarding',
                                ].includes(window.location.pathname)
                                    ? '/'
                                    : '#'
                            }
                            className={style.brandLogo}
                        >
                            <img
                                src={logo}
                                alt="logo"
                                onClick={() => {
                                    if (!(currentStep > 13))
                                        dispatch(openPopupTalkToExpert());
                                }}
                            />
                        </Navbar.Brand>
                        <div
                            className={`d-flex d-lg-none gap-3 ${style.button}`}
                        >
                            <div>
                                <Button
                                    className={style.loginBtn}
                                    onClick={() => {
                                        // const isNavigationAllowed = false;
                                        // handleSubmit(isNavigationAllowed);
                                        setShowLoginModal(true);
                                    }}
                                >
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                    )}
                                </Button>
                            </div>
                            {/* <Navbar.Toggle aria-controls="navbarScroll" className={style.custom_toggler} /> */}
                            <Button onClick={handleShow} className={style.canva_btn}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_4262_7253)">
                                            <path d="M19.1667 9.16641H0.833333C0.373096 9.16641 0 9.53951 0 9.99975C0 10.46 0.373096 10.8331 0.833333 10.8331H19.1667C19.6269 10.8331 20 10.46 20 9.99975C20 9.53951 19.6269 9.16641 19.1667 9.16641Z" fill="#7743DC" />
                                            <path d="M19.1667 3.33359H0.833333C0.373096 3.33359 0 3.70668 0 4.16692C0 4.62716 0.373096 5.00025 0.833333 5.00025H19.1667C19.6269 5.00025 20 4.62716 20 4.16692C20 3.70668 19.6269 3.33359 19.1667 3.33359Z" fill="#7743DC" />
                                            <path d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z" fill="#7743DC" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4262_7253">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Button>

                            <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                                <Offcanvas.Header closeButton>

                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                    <div className={style.navbarLink}>
                                        <Nav.Link href="#other-product-insurances">
                                            {translate(
                                                'SME_LAYOUT.HEADER.NORMAL_HEADER.PRODUCTS',
                                            )}
                                        </Nav.Link>
                                        <Nav.Link href={'/about-us'}>
                                            {translate(
                                                'SME_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                            )}
                                        </Nav.Link>
                                        <Nav.Link href="#news-section">
                                            {translate(
                                                'SME_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                            )}
                                        </Nav.Link>
                                    </div>

                                    <Form
                                        className={`d-flex  ${style.button} ${style.loginForms}`}
                                    >
                                        <Dropdown className={style.selectedLanguage}>
                                            <Dropdown.Toggle
                                                variant="light"
                                                id="language-dropdown"
                                            >
                                                <p>
                                                    <Globe />{' '}
                                                    {
                                                        languages.find(
                                                            (l: any) =>
                                                                l.name ===
                                                                selectedLanguage,
                                                        )?.display
                                                    }
                                                </p>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {languages.map((language, index) => (
                                                    <Dropdown.Item
                                                        className={style.dropdownItem}
                                                        key={index}
                                                        onClick={() =>
                                                            handleLanguageSelect(
                                                                language.name,
                                                            )
                                                        }
                                                    >
                                                        {language.display}{' '}
                                                        {selectedLanguage ===
                                                            language.name && (
                                                                <CheckCircleFill />
                                                            )}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className={style.custom_btns}>
                                        <Button
                                            className={`${style.loginBtn} ${style.hamLoginBtn}`}
                                            onClick={() => {
                                                // const isNavigationAllowed = false;
                                                // handleSubmit(isNavigationAllowed);
                                                setShowLoginModal(true);
                                            }}
                                        >
                                            {translate(
                                                'SME_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                            )}
                                        </Button>
                                        <Button
                                            className={style.startedBtn}
                                            onClick={() => navigate('/sme-onboarding')}
                                        >
                                            {translate(
                                                'SME_LAYOUT.HEADER.NORMAL_HEADER.GET_STARTED',
                                            )}
                                        </Button>
                                        </div>

                                    </Form>

                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                        <Navbar.Collapse id="navbarScroll" className={'d-none'}>
                            <Navbar.Toggle
                                aria-controls="navbarScroll"
                                className='toggle-close-icon'>
                                <img src={xcircle} alt="xcircle" className='close-icon' />
                            </Navbar.Toggle>
                            <Nav
                                className={`me-auto my-2 my-lg-0 ${style.navbarLink}`}
                                navbarScroll
                            >
                                <Nav.Link href="#other-product-insurances">
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.PRODUCTS',
                                    )}
                                </Nav.Link>
                                <Nav.Link href={'/about-us'}>
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.ABOUT_US',
                                    )}
                                </Nav.Link>
                                <Nav.Link href="#news-section">
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.NEWS',
                                    )}
                                </Nav.Link>
                            </Nav>
                            <Form
                                className={`d-flex gap-3 ${style.button} ${style.loginForms}`}
                            >
                                <Dropdown className={style.selectedLanguage}>
                                    <Dropdown.Toggle
                                        variant="light"
                                        id="language-dropdown"
                                    >
                                        <p>
                                            <Globe />{' '}
                                            {
                                                languages.find(
                                                    (l: any) =>
                                                        l.name ===
                                                        selectedLanguage,
                                                )?.display
                                            }
                                        </p>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {languages.map((language, index) => (
                                            <Dropdown.Item
                                                className={style.dropdownItem}
                                                key={index}
                                                onClick={() =>
                                                    handleLanguageSelect(
                                                        language.name,
                                                    )
                                                }
                                            >
                                                {language.display}{' '}
                                                {selectedLanguage ===
                                                    language.name && (
                                                    <CheckCircleFill />
                                                )}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button
                                    className={`${style.loginBtn} ${style.hamLoginBtn}`}
                                    onClick={() => {
                                        // const isNavigationAllowed = false;
                                        // handleSubmit(isNavigationAllowed);
                                        setShowLoginModal(true);
                                    }}
                                >
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.LOGIN',
                                    )}
                                </Button>
                                <Button
                                    className={style.startedBtn}
                                    onClick={() => navigate('/sme-onboarding')}
                                >
                                    {translate(
                                        'SME_LAYOUT.HEADER.NORMAL_HEADER.GET_STARTED',
                                    )}
                                </Button>

                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            {/* {continuePayment && (
                <div
                    className={`${style.policyHeader} ${style.policyWatingHeader}`}
                >
                    <Container>
                        <div className={style.policyTitle}>
                            <p>
                                Hi Sandeep. Your policy is waiting on you to
                                purchase. Continue from where you left off.
                            </p>
                            <div className={style.policyBtn}>
                                <Button>
                                    Continue to Payment <Download />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
            {failedPayment && (
                <div className={style.policyHeader}>
                    <Container>
                        <div className={style.policyTitle}>
                            <p>
                                Hi Sandeep. There appears to have been some
                                problem with your payment. Please try again.
                            </p>
                            <div className={style.policyBtn}>
                                <Button>
                                    Continue to Payment <Download />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )} */}

            <SMELogin
                showLoginModal={showLoginModal}
                setShowLoginModal={setShowLoginModal}
            />
        </>
    );
};

export default Header;
