import { Action } from 'redux';
import { ELanguagesOptions } from '../Reducers/PreferenceReducer';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export interface ChangeLanguageAction extends Action<typeof CHANGE_LANGUAGE> {
    payload: keyof ELanguagesOptions;
}

export const ChangeLanguageAction = (language : keyof ELanguagesOptions) => {
    return {
        type: CHANGE_LANGUAGE,
        payload: language,
    };
};