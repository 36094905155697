import React, { useEffect, useState } from 'react';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import logo from '../../assets/images/logo.svg';
// import search from '../../assets/images/search.svg';
// import searchWhite from '../../assets/images/Search-white.svg';

import CustomButton from '../Buttons/CustomButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    GetAllPackagesAction,
    GetPoliciesListAction,
} from '../../Redux/Actions/HomepageActions';
import { IReduxState } from '../../utils/types';
import CustomLoader from '../Loader/CustomLoader';
import { GAActions, GACategories } from '../../utils/googleAnalytics/gaData';
import useGAEvent from '../../utils/googleAnalytics/useGAEvent';

const ResourcesMenu = [
    {
        id: 1,
        label: 'Q1. Which type of business insurance should I purchase?',
        path: '/resource/q1',
    },
    {
        id: 2,
        label: 'Q2. How much does business insurance cost?',
        path: '/resource/q2',
    },
    {
        id: 3,
        label: "Q3. What's the difference between a broker and Covrzy?",
        path: '/resource/q3',
    },
];

const Header = () => {
    const { pathname } = useLocation();
    const { sendGAEvent } = useGAEvent(GACategories.Header);
    // const [openSearch, setOpenSearch] = useState(false);
    // const [openModal, setOpenModal] = useState(false);
    const [isHoveredForProducts, setIsHoveredForProducts] = useState(false);
    const [isClickedForProducts, setIsClickedForProducts] = useState(false);
    const [isHoveredForResources, setIsHoveredForResources] = useState(false);
    const [isClickedForResources, setIsClickedForResources] = useState(false);
    const [showPoliciesCount, setShowPoliciesCount] = useState(9);
    const [showPackageCount, setShowPackageCount] = useState(7);
    const { policies_list, package_list, loading } = useSelector(
        (state: IReduxState) => state.Homepage,
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            !(
                pathname?.includes('sign-in') ||
                pathname?.includes('reset-password')
            )
        ) {
            dispatch(GetPoliciesListAction());
            dispatch(GetAllPackagesAction());
        }
    }, [dispatch]);

    useEffect(() => {
        setIsHoveredForProducts(false);
        setIsClickedForProducts(false);
        setIsHoveredForResources(false);
        setIsClickedForResources(false);
    }, [pathname]);

    return (
        <>
            {/* <header className={openModal ? 'menu-open' : ''}> */}
            <header>
                <Navbar collapseOnSelect expand="sm">
                    <Container>
                        <Link to="/user/dashboard">
                            <img src={logo} alt="logo" />
                        </Link>
                        <p>&trade;</p>
                        {!(
                            pathname?.includes('sign-in') ||
                            pathname?.includes('reset-password')
                        ) && (
                            <>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse
                                    className="justify-content-end"
                                    id="responsive-navbar-nav"
                                >
                                    <Nav className="ml-auto">
                                        <NavDropdown
                                            className="mega-menu-wrp"
                                            title="Products"
                                            id="collasible-nav-dropdown"
                                            onMouseEnter={() =>
                                                setIsHoveredForProducts(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredForProducts(false)
                                            }
                                            onToggle={() =>
                                                setIsClickedForProducts(
                                                    !isClickedForProducts,
                                                )
                                            }
                                            show={
                                                isClickedForProducts ||
                                                isHoveredForProducts
                                            }
                                            // show={true}
                                        >
                                            <div className="mega-menu-box">
                                                <Container>
                                                    <Row>
                                                        <Col
                                                            lg={6}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <h3 className="mega-menu-title">
                                                                Packages
                                                            </h3>
                                                            <Row>
                                                                <div className="mega-menu-list">
                                                                    <Row>
                                                                        {loading ? (
                                                                            <Col
                                                                                lg={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <CustomLoader />
                                                                            </Col>
                                                                        ) : package_list.length !==
                                                                          0 ? (
                                                                            <>
                                                                                {package_list
                                                                                    ?.slice(
                                                                                        0,
                                                                                        showPackageCount,
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item:
                                                                                                | Object
                                                                                                | any,
                                                                                            i: number,
                                                                                        ) => (
                                                                                            <Col
                                                                                                lg={
                                                                                                    6
                                                                                                }
                                                                                                md={
                                                                                                    6
                                                                                                }
                                                                                                sm={
                                                                                                    12
                                                                                                }
                                                                                                key={
                                                                                                    item?.id
                                                                                                }
                                                                                                className="policies-menu-list"
                                                                                            >
                                                                                                <Link
                                                                                                    to={`/package-details/${item?.id}`}
                                                                                                >
                                                                                                    {
                                                                                                        item?.package_name
                                                                                                    }
                                                                                                </Link>
                                                                                            </Col>
                                                                                        ),
                                                                                    )}
                                                                                {package_list.length >
                                                                                    7 &&
                                                                                    showPackageCount ===
                                                                                        7 && (
                                                                                        <Col
                                                                                            lg={
                                                                                                6
                                                                                            }
                                                                                            md={
                                                                                                6
                                                                                            }
                                                                                            sm={
                                                                                                12
                                                                                            }
                                                                                            className="policies-menu-list"
                                                                                            onClick={() =>
                                                                                                setShowPackageCount(
                                                                                                    package_list?.length,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            ALL
                                                                                            PACKAGES{' '}
                                                                                            <i
                                                                                                className="fa fa-angle-right"
                                                                                                aria-hidden="true"
                                                                                            ></i>
                                                                                        </Col>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            <Col
                                                                                lg={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <Link to="/">
                                                                                    No
                                                                                    Records
                                                                                    Found
                                                                                </Link>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                        <Col
                                                            lg={6}
                                                            md={6}
                                                            sm={6}
                                                            xs={6}
                                                        >
                                                            <h3 className="mega-menu-title">
                                                                Policies
                                                            </h3>
                                                            <Row>
                                                                <div className="mega-menu-list">
                                                                    <Row>
                                                                        {loading ? (
                                                                            <Col
                                                                                lg={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <CustomLoader />
                                                                            </Col>
                                                                        ) : policies_list.length !==
                                                                          0 ? (
                                                                            <>
                                                                                {policies_list
                                                                                    ?.slice(
                                                                                        0,
                                                                                        showPoliciesCount,
                                                                                    )
                                                                                    .map(
                                                                                        (
                                                                                            item:
                                                                                                | Object
                                                                                                | any,
                                                                                            i: number,
                                                                                        ) => (
                                                                                            <Col
                                                                                                lg={
                                                                                                    6
                                                                                                }
                                                                                                key={
                                                                                                    item?.id
                                                                                                }
                                                                                                className="policies-menu-list"
                                                                                            >
                                                                                                <Link
                                                                                                    to={`/policy-details/${item?.id}`}
                                                                                                >
                                                                                                    {
                                                                                                        item?.policy_name
                                                                                                    }
                                                                                                </Link>
                                                                                            </Col>
                                                                                        ),
                                                                                    )}
                                                                                {policies_list.length >
                                                                                    9 &&
                                                                                    showPoliciesCount ===
                                                                                        9 && (
                                                                                        <Col
                                                                                            lg={
                                                                                                6
                                                                                            }
                                                                                            className="policies-menu-list"
                                                                                            onClick={() =>
                                                                                                setShowPoliciesCount(
                                                                                                    policies_list?.length,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            ALL
                                                                                            POLICIES{' '}
                                                                                            <i
                                                                                                className="fa fa-angle-right"
                                                                                                aria-hidden="true"
                                                                                            ></i>
                                                                                        </Col>
                                                                                    )}
                                                                            </>
                                                                        ) : (
                                                                            <Col
                                                                                lg={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <Link to="/">
                                                                                    No
                                                                                    Records
                                                                                    Found
                                                                                </Link>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                        </NavDropdown>
                                        <div className="header-link">
                                            <Link to="about-us">About Us</Link>
                                        </div>
                                        <div className="header-link">
                                            <Link to="partners">Partners</Link>
                                        </div>
                                        <NavDropdown
                                            className="mega-menu-wrp resources-menu-box"
                                            title="Resources"
                                            id="collasible-nav-dropdown"
                                            onMouseEnter={() =>
                                                setIsHoveredForResources(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsHoveredForResources(false)
                                            }
                                            onToggle={() =>
                                                setIsClickedForResources(
                                                    isClickedForProducts,
                                                )
                                            }
                                            show={
                                                isClickedForResources ||
                                                isHoveredForResources
                                            }
                                        >
                                            <div className="mega-menu-box ">
                                                <Container>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div className="mega-menu-list">
                                                                        {ResourcesMenu.map(
                                                                            (
                                                                                item,
                                                                            ) => (
                                                                                <Col
                                                                                    lg={
                                                                                        12
                                                                                    }
                                                                                    className="policies-menu-list"
                                                                                    key={
                                                                                        item?.id
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            item?.path
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            item?.label
                                                                                        }
                                                                                    </Link>
                                                                                </Col>
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                                {/* <Container>
                                                    <Row>
                                                        <Col lg={6}>
                                                            <h3 className='mega-menu-title'>Insurance for</h3>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className='mega-menu-list'>
                                                                        <NavDropdown.Item href="#">Startups <em>(VC Funded)</em></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Tech Companies <em>(Not VC Funded)</em></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Law Firms</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">VC & Private Equity Firms</NavDropdown.Item>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className='mega-menu-list'>
                                                                        <NavDropdown.Item href="#">Cannabis Companies <span>NEW</span></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Aviation & Aerospace <span>NEW</span></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Small Businesses</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">ALL INDUSTRIES  <i className="fa fa-angle-right" aria-hidden="true"></i></NavDropdown.Item>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <h3 className='mega-menu-title'>Policies</h3>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className='mega-menu-list'>
                                                                        <NavDropdown.Item href="#">Business Owners Policy</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Commercial Crime</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Cyber incl. Data Breach</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Directors & Officers</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Employment Practices Liability</NavDropdown.Item>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className='mega-menu-list'>
                                                                        <NavDropdown.Item href="#">Key Person Insurance <span>NEW</span></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Professional Liability</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Tech Errors & Omissions <span>NEW</span></NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">Small Businesses</NavDropdown.Item>
                                                                        <NavDropdown.Item href="#">ALL POLICIES <i className="fa fa-angle-right" aria-hidden="true"></i></NavDropdown.Item>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Container> */}
                                            </div>
                                        </NavDropdown>
                                        <div className="signin-wrp signin-wrp-mobile">
                                            <Link
                                                to={'/sign-in'}
                                                className="signin-btn"
                                            >
                                                Sign In
                                            </Link>
                                            <CustomButton
                                                buttonTitle="GET QUOTE"
                                                onClick={() =>
                                                    navigate(
                                                        '/quote/insurance-packages',
                                                    )
                                                }
                                            />
                                        </div>
                                    </Nav>
                                    <div className="signin-wrp signin-wrp-desktop">
                                        {/* <div className='search-bar'>
                                            <div className="search-icon" id="search" onClick={() => setOpenSearch(!openSearch)}><img src={search} alt='' /></div>
                                            <div className={openSearch ? "search_box active" : "search_box"} id="search_box">
                                                <Form.Control type="text" name="search_criteria" placeholder="Search by keyword" />
                                                <Button><img src={searchWhite} alt='' /></Button>
                                            </div>
                                        </div> */}
                                        <Link
                                            to={'/sign-in'}
                                            className="signin-btn"
                                            onClick={() =>
                                                sendGAEvent(
                                                    GAActions.SignInClicked,
                                                )
                                            }
                                        >
                                            Sign In
                                        </Link>
                                        <CustomButton
                                            buttonTitle="GET QUOTE"
                                            onClick={() => {
                                                sendGAEvent(
                                                    GAActions.GetQuoteClicked,
                                                );
                                                navigate(
                                                    '/quote/insurance-packages',
                                                );
                                            }}
                                        />
                                    </div>
                                </Navbar.Collapse>
                            </>
                        )}
                    </Container>
                </Navbar>
            </header>
        </>
    );
};

export default Header;
