import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { IReduxState } from '../../utils/types';

interface IAuthLayout {
    component: JSX.Element;
}

function AuthLayout({ component }: IAuthLayout): JSX.Element {
    const { isLogin } = useSelector((state: IReduxState) => state.Auth);

    return (
        <Fragment>
            {isLogin ? <Navigate to={'/user/dashboard'} /> : <>{component}</>}
        </Fragment>
    );
}

export default AuthLayout;
