import {
    Col,
    Container,
    Dropdown,
    Nav,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const ResourcesMenu = [
    {
        id: 1,
        label: 'Q1. Which type of business insurance should I purchase?',
        path: '/resource/q1',
    },
    {
        id: 2,
        label: 'Q2. How much does business insurance cost?',
        path: '/resource/q2',
    },
    {
        id: 3,
        label: "Q3. What's the difference between a broker and Covrzy?",
        path: '/resource/q3',
    },
];

const Footer = () => {
    const [isHoveredForResources, setIsHoveredForResources] = useState(false);
    const [isClickedForResources, setIsClickedForResources] = useState(false);
    return (
        <>
            <footer className="footer">
                <Container>
                    <Row>
                        <Col md={3} sm={6}>
                            <h3 className="title-widget">Quick Access</h3>
                            <div className="quitck-link d-grid">
                                {/* <Link to='/'>Products</Link> */}
                                <Link to="/about-us">About Us</Link>
                                <Link to="/faqs">FAQs</Link>
                                <Link to="/user/user-detail">Get Started</Link>
                                <Link to="/partners">Partner with us</Link>
                                {/* <Link to='/'>Resources</Link> */}

                                <Dropdown className="resources-footer">
                                    <Dropdown.Toggle
                                        variant=""
                                        id="dropdown-basic"
                                    >
                                        Resources
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {/* <Dropdown.Item href="/resource/q1">Q1. Which type of business insurance should I purchase?</Dropdown.Item> */}
                                        <Link
                                            className="dropdown-item"
                                            to="/resource/q1"
                                        >
                                            Q1. Which type of business insurance
                                            should I purchase?
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/resource/q2"
                                        >
                                            Q2. How much does business insurance
                                            cost?
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to="/resource/q3"
                                        >
                                            Q3. What's the difference between a
                                            broker and Covrzy?
                                        </Link>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {/* <Nav.Link href="#">About Us</Nav.Link>
                                <Nav.Link href="#">Careers</Nav.Link>
                                <Nav.Link href="#">Media Center</Nav.Link>
                                <Nav.Link href="#">Partner Program</Nav.Link>
                                <Nav.Link href="#">Affiliate Program</Nav.Link>
                                <Nav.Link href="#">FAQs</Nav.Link>
                                <Nav.Link href="#">Sitemap</Nav.Link>
                                <Nav.Link href="#">Claims</Nav.Link> */}
                                <Link to="/sign-in">Sign In</Link>
                            </div>
                        </Col>
                        <Col md={3} sm={6}>
                            <h3 className="title-widget">Contact Us</h3>
                            <div className="contact-info">
                                <div className="contact-list">
                                    <p>For support and other queries</p>
                                    <Nav.Link href="mailto:hello@covrzy.com">
                                        hello@covrzy.com
                                    </Nav.Link>
                                </div>
                                {/* <div className="contact-list">
                                    <p>Press Inquiries</p>
                                    <Nav.Link href="tel:98765 543210">98765 543210</Nav.Link>
                                </div> */}
                                <div className="contact-list mail-info">
                                    {/* <Nav.Link href="mailto:hello@covrzy.comlaims">hello@covrzy.comlaims</Nav.Link>
                                    <Nav.Link href="tel:1 844 436 2765">1 844 436 2765</Nav.Link> */}
                                    <p>Monday to Friday</p>
                                    <p>9 AM - 6 PM IST</p>
                                </div>
                                <div className="contact-list phone-number">
                                    <p>Talk to us</p>
                                    <Nav.Link href="tel:9354963947">
                                        9354963947
                                    </Nav.Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} sm={6}>
                            <h3 className="title-widget">Useful Links</h3>
                            <div className="contact-info">
                                <div className="contact-list">
                                    <p>General Inquiries</p>
                                    <Nav.Link href="mailto:hello@covrzy.com">
                                        hello@covrzy.com
                                    </Nav.Link>
                                </div>
                                {/* <div className="contact-list">
                                    <p>Broker Partner Inquiries</p>
                                    <Nav.Link href="mailto:brokers@covrzy.com">brokers@covrzy.com</Nav.Link>
                                </div>
                                <div className="contact-list">
                                    <p>VCs & Partnerships</p>
                                    <Nav.Link href="mailto:partnerships@covrzy.com">partnerships@covrzy.com</Nav.Link>
                                </div>
                                <div className="contact-list">
                                    <p>Press</p>
                                    <Nav.Link href="mailto:press@covrzy.com">press@covrzy.com</Nav.Link>
                                </div>
                                <div className="contact-list">
                                    <p>Security Inquiries</p>
                                    <Nav.Link href="mailto:security@covrzy.com">security@covrzy.com</Nav.Link>
                                </div> */}
                            </div>
                        </Col>
                        <Col md={3} sm={6}>
                            <h3 className="title-widget">Meet Us</h3>
                            <div className="address">
                                <p>
                                    A 506, Carlton Towers, No 1 Old Airport Road, Bengaluru, Karnataka 560008
                                </p>
                                <div className="social-media">
                                    <Nav.Link
                                        href="https://www.linkedin.com/company/covrzy/?viewAsMember=true"
                                        target={'_blank'}
                                    >
                                        <i
                                            className="fa fa-linkedin"
                                            aria-hidden="true"
                                        ></i>
                                    </Nav.Link>
                                    <Nav.Link
                                        href="https://www.google.com/search?q=covrzy&oq=covrzy&aqs=chrome..69i57j0i10i512j69i59j69i60l2j69i61j69i65l2.3095j0j4&sourceid=chrome&ie=UTF-8"
                                        target={'_blank'}
                                    >
                                        <i
                                            className="fa fa-google"
                                            aria-hidden="true"
                                        ></i>
                                    </Nav.Link>
                                    <Nav.Link
                                        href="https://www.instagram.com/covrzy/"
                                        target={'_blank'}
                                    >
                                        <i
                                            className="fa fa-instagram instagram-icon"
                                            aria-hidden="true"
                                        ></i>
                                    </Nav.Link>
                                    <Nav.Link
                                        href="https://twitter.com/Covrzy_In"
                                        target={'_blank'}
                                    >
                                        <i
                                            className="fa fa-twitter twitter-icon"
                                            aria-hidden="true"
                                        ></i>
                                    </Nav.Link>
                                    {/* <Nav.Link href="#"><i className="fa fa-facebook" aria-hidden="true"></i></Nav.Link> */}
                                    {/* <Nav.Link href="https://mail.google.com" target={"_blank"}><i className="fa fa-envelope" aria-hidden="true"></i></Nav.Link> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <div className="copyright-section">
                <Container>
                    <Row>
                        {/* <Col md={6}><p>© 2022 COVRZY Insurance Services, LLC. All rights reserved.</p></Col> */}
                        <Col md={6}>
                            <p>
                                ©{' '}
                                {new Date().getFullYear()} Bizcovr Insurance Broking Private Limited
                            </p>
                        </Col>
                        <Col md={6}>
                            <div className="copyright-link">
                                <Link to="company/terms">Terms</Link>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                {/* <Nav.Link href="#">Licenses</Nav.Link>
                                <Nav.Link href="#">Disclosure</Nav.Link> */}
                            </div>
                        </Col>
                        <Col md={12}>
                            <p className="text-center">
                                IRDAI Broking License Registration Code: Certificate No. 981 , License category- Direct Broker (General), valid till 14-July-2027.
                            </p>
                            <p className="text-center">
                                The information provided on this website does
                                not constitute insurance advice. All content and
                                materials are for general informational purposes
                                only. Complete COVRZY’s online application and
                                contact one of our licensed insurance
                                professionals to obtain advice for your specific
                                business insurance needs.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Footer;
