import { useEffect, useLayoutEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import style from './style.module.scss';
import logo from '../../assets/images/logo.png';
import {
    QuestionCircle,
    CheckCircleFill,
    Globe,
    Download,
    ArrowRight,
} from 'react-bootstrap-icons';
import { Dropdown } from 'react-bootstrap';
import {
    exitCallbackModalHandle,
    getMerchantQuotationSteps,
    resetMerchantStepDetails,
    updateStep,
    userLogoutResetSMEDetails,
} from '../../Redux/Actions/SMEActions';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutAction } from '../../Redux/Actions/AuthActions';
import { IReduxState } from '../../utils/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { current } from '@reduxjs/toolkit';
import { DecryptData } from '../CryptoJSToken';
import { GetPaymentHistoryAction } from '../../Redux/Actions/PaymentActions';
import SMEOffcanvas from '../../components/SMEOffcanvas';
import { ELanguagesOptions } from '../../Redux/Reducers/PreferenceReducer';
import { ChangeLanguageAction } from '../../Redux/Actions/PreferenceActions';
import { useTranslation } from 'react-i18next';
import axios from '../../axiosConfig';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import {
    openPopupGetHelp,
    openPopupTalkToExpert,
} from '../../Redux/Actions/PopupActions';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';

const AuthHeader = () => {
    const [selectedLanguage, setSelectedLanguage] = useState('english');
    const BETA_LINK = process.env.REACT_APP_BETA_URL
    
    const languages = [
        {
            name: 'english',
            display: 'English',
        },
        {
            name: 'hindi',
            display: 'हिंदी',
        }
    ];
    const [continuePayment, setContinuePayment] = useState(false);
    const [failedPayment, setFailedPayment] = useState(false);
    const [deletingDraft, setDeletingDraft] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t: translate } = useTranslation();

    const location = useLocation();

    const AuthState = useSelector((state: IReduxState) => state.Auth);
    const Preference = useSelector((state: IReduxState) => state.Preference);

    const userData = DecryptData(AuthState?.user) ?? AuthState?.user ?? {};

    const UserSession = useSelector((state: IReduxState) => state.UserSession);

    const { paymentResponse, payment_history } = useSelector(
        (state: IReduxState) => state.Payment,
    );

    const { quotation, currentStep, maxStep, insuranceType } = useSelector(
        (state: IReduxState) => state.SMEDetails,
    );

    const { getHelp } = useSelector((state: IReduxState) => state.Popup);

    useEffect(() => {
        const isPaymentFailed =
            payment_history?.length > 0 &&
                payment_history?.find(
                    (p: any) => p.correlationId === quotation?.correlationId,
                ) &&
                payment_history?.find(
                    (p: any) => p.correlationId === quotation?.correlationId,
                )?.paymentStatus !== 'completed'
                ? true
                : false;

        const isPaymentDone =
            !payment_history
                ?.map((p: any) => p.correlationId)
                ?.includes(quotation?.correlationId) && currentStep === 13;

        payment_history
            ?.map((p: any) => p.correlationId)
            ?.includes(quotation?.correlationId);
        setFailedPayment(isPaymentFailed);
        setContinuePayment(isPaymentDone);
    }, [quotation, paymentResponse, payment_history]);

    const handleUserLogOut = () => {
        dispatch(userLogoutResetSMEDetails());
        dispatch(LogoutAction());
    };

    const handleDeleteDraft = async () => {
        setDeletingDraft(true);
        try {
            const response = await axios.delete('/v1/step');
            if (response.data.status === 'success') {
                dispatch({
                    type: TOAST_SHOW,
                    payload: {
                        message:
                            'Draft deleted successfully. You can continue with new draft.',
                        severity: 'success',
                        show: true,
                    },
                });

                dispatch(resetMerchantStepDetails());

                if (AuthState.isLogin && UserSession?.user) {
                    dispatch(getMerchantQuotationSteps());
                    dispatch(GetPaymentHistoryAction());
                }
            } else {
                dispatch({
                    type: TOAST_SHOW,
                    payload: {
                        message:
                            'Something went wrong while deleting draft. Please try again later.',
                        severity: 'danger',
                        show: true,
                    },
                });
            }
        } catch (err: any) {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message:
                        err?.response?.data?.errors?.Error?.message ||
                        'Something went wrong',
                    severity: 'danger',
                    show: true,
                },
            });
        } finally {
            setDeletingDraft(false);
        }
    };

    const handleLanguageSelect = (language: any) => {
        setSelectedLanguage(language);
        dispatch(ChangeLanguageAction(language as keyof ELanguagesOptions));
    };

    const handleContinueClick = () => {
        if (insuranceType === 'fire') {
            navigate('/buy-fire-insurance');
        } else {
            navigate('/buy-stock-insurance');
        }
    };

    const handleGoToDashboardClick = () => {
        if (BETA_LINK) {
            window.location.href = BETA_LINK + "/user/dashboard"
        } else {
            if (insuranceType === 'fire') {
                navigate('/buy-fire-insurance');
            } else {
                navigate('/buy-stock-insurance');
            }
        }
    }

    useEffect(() => {
        if (AuthState.isLogin && UserSession?.user) {
            dispatch(getMerchantQuotationSteps());
            dispatch(GetPaymentHistoryAction());
        }
    }, []);

    useLayoutEffect(() => {
        setSelectedLanguage(Preference?.language);
    }, [Preference?.language]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showLoginModal, setShowLoginModal] = useState(false);

    return (
        <div className={style.authHeaderSection}>
            <Navbar
                expand="lg"
                className={`bg-body-tertiary  ${style.navNavbar}`}
            >
                <Container>
                    <Navbar.Brand
                        href={
                            currentStep > 13 ||
                                ![
                                    '/buy-fire-insurance',
                                    '/buy-stock-insurance',
                                    '/sme-onboarding',
                                ].includes(window.location.pathname)
                                ? '/'
                                : '#'
                        }
                        className={style.brandLogo}
                    >
                        <img
                            src={logo}
                            alt="logo"
                            onClick={() => {
                                if (!(currentStep > 13))
                                    dispatch(openPopupTalkToExpert());
                            }}
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" className={style.custom_toggler1} />

                    <Button onClick={handleShow} className={style.canva_btn1}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_4262_7253)">
                                            <path d="M19.1667 9.16641H0.833333C0.373096 9.16641 0 9.53951 0 9.99975C0 10.46 0.373096 10.8331 0.833333 10.8331H19.1667C19.6269 10.8331 20 10.46 20 9.99975C20 9.53951 19.6269 9.16641 19.1667 9.16641Z" fill="#7743DC" />
                                            <path d="M19.1667 3.33359H0.833333C0.373096 3.33359 0 3.70668 0 4.16692C0 4.62716 0.373096 5.00025 0.833333 5.00025H19.1667C19.6269 5.00025 20 4.62716 20 4.16692C20 3.70668 19.6269 3.33359 19.1667 3.33359Z" fill="#7743DC" />
                                            <path d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z" fill="#7743DC" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4262_7253">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </Button>

                            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                                <Offcanvas.Header closeButton>

                                </Offcanvas.Header>
                                <Offcanvas.Body >
                                <Form className={`offcanvasHeader gap-3 ${style.button}`}>
                            <Button
                                className={style.logoutBtnNavbar}
                                onClick={handleUserLogOut}
                            >
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.LOGOUT',
                                )}
                            </Button>
                            <Button
                                className={style.btnOutline}
                                onClick={() => {
                                    dispatch(openPopupGetHelp());
                                }}
                            >
                                <QuestionCircle />{' '}
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.GET_HELP',
                                )}
                            </Button>
                            <Dropdown className={style.selectedLanguage}>
                                <Dropdown.Toggle
                                    variant="light"
                                    id="language-dropdown"
                                >
                                    <p>
                                        <Globe />{' '}
                                        {
                                            languages.find(
                                                (l: any) =>
                                                    l.name === selectedLanguage,
                                            )?.display
                                        }
                                    </p>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {languages.map((language, index) => (
                                        <Dropdown.Item
                                            className={style.dropdownItem}
                                            key={index}
                                            onClick={() =>
                                                handleLanguageSelect(
                                                    language.name,
                                                )
                                            }
                                        >
                                            {language.display}{' '}
                                            {selectedLanguage ===
                                                language.name && (
                                                    <CheckCircleFill />
                                                )}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form>

                                    

                                </Offcanvas.Body>
                            </Offcanvas>

                    <Navbar.Collapse id="navbarScroll" className={style.custom_toggler1}>
                        <Form className={`d-flex gap-3 ${style.button}`}>
                            <Button
                                className={style.logoutBtn}
                                onClick={handleUserLogOut}
                            >
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.LOGOUT',
                                )}
                            </Button>
                            <Button
                                className={style.btnOutline}
                                onClick={() => {
                                    dispatch(openPopupGetHelp());
                                }}
                            >
                                <QuestionCircle />{' '}
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.GET_HELP',
                                )}
                            </Button>
                            <Dropdown className={style.selectedLanguage}>
                                <Dropdown.Toggle
                                    variant="light"
                                    id="language-dropdown"
                                >
                                    <p>
                                        <Globe />{' '}
                                        {
                                            languages.find(
                                                (l: any) =>
                                                    l.name === selectedLanguage,
                                            )?.display
                                        }
                                    </p>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {languages.map((language, index) => (
                                        <Dropdown.Item
                                            className={style.dropdownItem}
                                            key={index}
                                            onClick={() =>
                                                handleLanguageSelect(
                                                    language.name,
                                                )
                                            }
                                        >
                                            {language.display}{' '}
                                            {selectedLanguage ===
                                                language.name && (
                                                    <CheckCircleFill />
                                                )}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {location.pathname === '/' && continuePayment && (
                <div
                    className={`${style.policyHeader} ${style.policyWatingHeader}`}
                >
                    <Container>
                        <div className={style.policyTitle}>
                            <p>
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_WAITING_FOR_PURCHASE_CONTENT',
                                    {
                                        USER_NAME:
                                            userData?.first_name ?? 'N/A',
                                    },
                                )}
                            </p>
                            <div className={style.policyBtn}>
                                {currentStep < 14 && (
                                    <Button onClick={handleDeleteDraft}>
                                        {translate(
                                            'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_DRAFT_DISCARD_BUTTON',
                                        )}
                                    </Button>
                                )}
                                <Button onClick={handleContinueClick}>
                                    {translate(
                                        'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_WAITING_FOR_PURCHASE_BUTTON',
                                    )}
                                    <ArrowRight />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
            {location.pathname === '/' &&
                ((currentStep > 1 && currentStep < 13) || currentStep > 13) &&
                currentStep !== maxStep &&
                !failedPayment && (
                    <div
                        className={`${style.policyHeader} ${style.policyWatingHeader}`}
                    >
                        <Container>
                            <div className={style.policyTitle}>
                                <p>
                                    {translate(
                                        'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_DRAFT_CONTENT',
                                        {
                                            USER_NAME:
                                                userData?.first_name ?? 'N/A',
                                        },
                                    )}
                                </p>
                                <div className={style.policyBtn}>
                                    {currentStep < 14 && (
                                        <Button onClick={handleDeleteDraft}>
                                            {translate(
                                                'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_DRAFT_DISCARD_BUTTON',
                                            )}
                                        </Button>
                                    )}
                                    <Button onClick={handleContinueClick}>
                                        {translate(
                                            'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_DRAFT_BUTTON',
                                        )}
                                        <ArrowRight />
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            {location.pathname === '/' && failedPayment && (
                <div
                    className={`${style.policyHeader} ${style.policyWatingHeader}`}
                >
                    <Container>
                        <div className={style.policyTitle}>
                            <p>
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_PROBLEM_WITH_PAYMENT_CONTENT',
                                    {
                                        USER_NAME:
                                            userData?.first_name ?? 'N/A',
                                    },
                                )}
                            </p>
                            <div className={style.policyBtn}>
                                {currentStep < 14 && (
                                    <Button onClick={handleDeleteDraft}>
                                        {translate(
                                            'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_DRAFT_DISCARD_BUTTON',
                                        )}
                                    </Button>
                                )}
                                <Button
                                    onClick={() => {
                                        dispatch(updateStep(13));
                                        handleContinueClick();
                                    }}
                                >
                                    {translate(
                                        'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_PROBLEM_WITH_PAYMENT_BUTTON',
                                    )}{' '}
                                    <ArrowRight />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
            {location.pathname === '/' && currentStep === maxStep && (
                <div className={`${style.policyHeader}`}>
                    <Container>
                        <div className={style.policyTitle}>
                            <p>
                                {translate(
                                    'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_POLICY_PURCHASE_COMPLETE_CONTENT',
                                    {
                                        USER_NAME:
                                            userData?.first_name ?? 'N/A',
                                        PAYMENT_CONFIRMATION_ID:
                                            payment_history[0]
                                                ?.paymentStatus === 'completed'
                                                ? payment_history[0]?.receiptId
                                                : 'N/A',
                                    },
                                )}
                            </p>
                            <div className={style.policyBtn}>
                                <Button onClick={handleGoToDashboardClick}>
                                    {translate(
                                        'SME_LAYOUT.HEADER.LOGGED_IN_HEADER.RIBBON_POLICY_PURCHASE_COMPLETE_BUTTON',
                                    )}{' '}
                                    <ArrowRight />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )}
            <SMEOffcanvas show={getHelp} />
        </div>
    );
};

export default AuthHeader;
