import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import SMELanding from '../../pages/SMELanding'
import AuthHeader from './AuthHeader'
import SMEStockInsurance from '../../pages/SMEStockInsurance'
import { useNavigate } from 'react-router-dom'

interface SMELayout {
    children: JSX.Element,
    isLoggedIn: boolean
}

const SMELayout = ({ children, isLoggedIn }: SMELayout) => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!isLoggedIn) {
    //         navigate('/')
    //     }
    // }, [isLoggedIn, navigate])

    return (
        <>
            {isLoggedIn ? (
                <div>
                    <AuthHeader />
                    <div>
                        {children}
                    </div>
                </div>
            ) : (
                <div>
                    <Header />
                    {children}
                </div>
            )}
        </>
    )
}

export default SMELayout