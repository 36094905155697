import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './styles.module.scss';
import IconStroke from '../../assets/images/Icon-troke.png';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import { phonesRegx } from '../../Helper/commonFunction';
import { PHONE } from '../../constants/main';
import { useFormik } from 'formik';
import CustomLabel from '../../common/CustomLabel';
import CustomPhoneInput from '../../common/PhoneInput';
import moment from 'moment';
import axios from '../../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_SHOW } from '../../Redux/Actions/ToastAction';
import { IReduxState } from '../../utils/types';
import { DecryptData } from '../../common/CryptoJSToken';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Clock, XLg } from 'react-bootstrap-icons';
import {
    closePopupRequestCallback,
    closePopupTalkToExpertRequestCallback,
} from '../../Redux/Actions/PopupActions';
import { useTranslation } from 'react-i18next';

const RequestCallbackFormModal: React.FC = ({ }) => {
    // Custom hook to get authentication state from Redux store
    const AuthState = useSelector((state: IReduxState) => state.Auth);

    // Decrypt user data from authentication state
    const userData = DecryptData(AuthState.user);

    // Translation function
    const { t: translate } = useTranslation();

    // State variables
    const [countryCode, setCountryCode] = useState<string>('91');
    const [submittingResponse, setSubmittingResponse] = useState(false);

    // Select requestCallback from Redux store
    const { requestCallback } = useSelector((state: IReduxState) => {
        return state.Popup;
    });

    // Modal state
    const [showModal, setShowModal] = useState(false);

    // Redux dispatch function
    const dispatch = useDispatch();

    // Form validation schema
    const callbackValidationSchema = yup.object().shape({
        firstName: yup
            .string()
            .required(
                translate(
                    'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.FIRST_NAME.ERRORS.REQUIRED',
                ),
            ),
        lastName: yup
            .string()
            .required(
                translate(
                    'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.LAST_NAME.ERRORS.REQUIRED',
                ),
            ),
        phone: yup
            .string()
            .required(
                translate(
                    'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.PHONE.ERRORS.REQUIRED',
                ),
            )
            .matches(phonesRegx[countryCode], PHONE),
        date: yup
            .string()
            .required(
                translate(
                    'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.DATE.ERRORS.REQUIRED',
                ),
            ),
        time: yup
            .string()
            .required(
                translate(
                    'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.TIME.ERRORS.REQUIRED',
                ),
            ),
    });

    /**
     * Handles form submission.
     *
     * @param {Object} values - The form values.
     * @returns {Promise<void>}
     */
    const handleFormSubmission = async (values: any) => {
        setSubmittingResponse(true);

        const { firstName, lastName, phone, date, time } = values;

        // Create the payload for the callback request
        const payload = {
            name: firstName + ' ' + lastName,
            phone: phone,
            date: date,
            time: time,
        };

        try {
            // Send the callback request
            await axios.post('v1/callback-requests', payload);

            // Show success toast and handle callback close
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Request registered successfully.',
                    severity: 'success',
                    show: true,
                },
            });
            setSubmittingResponse(false);
            handleCallbackClose();
        } catch {
            // Show error toast and handle callback close
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Something went wrong. Please try again.',
                    severity: 'danger',
                    show: true,
                },
            });
            setSubmittingResponse(false);
        }
    };

    /**
     * Formik form configuration.
     *
     * @type {Object}
     */
    const callbackFormik = useFormik({
        initialValues: {
            firstName: userData?.first_name ?? '',
            lastName: userData?.last_name ?? '',
            phone: userData?.phone_number ?? '',
            date: '',
            time: '',
        },
        validationSchema: callbackValidationSchema,
        onSubmit: handleFormSubmission,
    });

    /**
     * Handle change event for form fields.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
     * @returns {void}
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        callbackFormik?.setFieldValue(name, value);
    };

    /**
     * Handle callback close.
     *
     * @returns {void}
     */
    const handleCallbackClose = () => {
        callbackFormik?.resetForm();
        dispatch(
            closePopupTalkToExpertRequestCallback(
                requestCallback?.talkToExpert,
            ),
        );
    };

    // Define the clock format to be used in the TimePicker component
    const clockFormat = 'h:mm a';

    /**
     * Custom form control for handling time input using the TimePicker component.
     *
     * @param {Object} props - The props object containing the children.
     * @param {ReactNode} props.children - The children components.
     * @param {Object} props.ref - The ref object.
     * @returns {ReactNode} The TimePicker component.
     */
    const CustomFormControl = React.forwardRef(
        ({ children }: any, ref: any) => {
            /**
             * Handles the change event for the TimePicker component.
             * Updates the 'time' field in the formik form with the selected time.
             *
             * @param {Moment} value - The selected time.
             */
            function onCustomFormControlChange(value: any) {
                if (value && value.format(clockFormat)) {
                    callbackFormik?.setFieldValue(
                        'time',
                        value.format(clockFormat),
                    );
                } else {
                    callbackFormik?.setFieldValue('time', '');
                }
            }

            return (
                <>
                    {/* TimePicker component for selecting the time */}
                    <TimePicker
                        name="time"
                        showSecond={false}
                        onChange={onCustomFormControlChange}
                        format={clockFormat}
                        use12Hours
                        value={
                            (callbackFormik?.values?.time &&
                                moment(
                                    callbackFormik?.values?.time,
                                    clockFormat,
                                )) ||
                            undefined
                        }
                        className={styles.timePicker}
                        popupClassName={styles.timePickerPopUp}
                        placeholder="hh:mm am"
                        inputIcon={
                            (!callbackFormik?.values?.time && <Clock />) ||
                            undefined
                        }
                        clearIcon={
                            (callbackFormik?.values?.time && <XLg />) ||
                            undefined
                        }
                    />
                </>
            );
        },
    );

    // Use effect hook to handle the opening and closing of the callback modal
    useEffect(() => {
        // If the request callback is shown, set the showModal state to true
        if (requestCallback.show) {
            setShowModal(true);
        } else {
            // If the request callback is not shown, set the showModal state to false
            setShowModal(false);
        }
    }, [requestCallback]);

    return (
        <Modal
            show={showModal}
            onHide={handleCallbackClose}
            centered
            className={styles.formPopop}
        >
            <Button className={styles.closeIcon} onClick={handleCallbackClose}>
                <XLg />
            </Button>
            <div className={styles.formModel}>
                <div>
                    <div className={styles.formBox}>
                        <p>
                            {translate(
                                'COMMON_COMPONENTS.REQUEST_CALLBACK.TITLE',
                            )}
                        </p>
                        <h2>
                            {translate(
                                'COMMON_COMPONENTS.REQUEST_CALLBACK.TITLE2',
                            )}
                        </h2>
                    </div>
                    <Form onSubmit={callbackFormik?.handleSubmit}>
                        <div className={styles.formlabel}>
                            <div className={styles.formGroup}>
                                <input
                                    id="date"
                                    className="form-control"
                                    name="date"
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    value={callbackFormik?.values?.date}
                                    min={moment()
                                        .add(1, 'days')
                                        .format('YYYY-MM-DD')}
                                />
                                <label>
                                    {translate(
                                        'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.DATE.TITLE',
                                    )}
                                    <span className="gl-form-asterisk"></span>
                                </label>
                                {callbackFormik?.errors?.date &&
                                    callbackFormik?.touched?.date && (
                                        <p className="text-danger">
                                            {callbackFormik?.errors?.date}
                                        </p>
                                    )}
                            </div>
                            <div className={styles.formGroup}>
                                <Form.Control
                                    type="time"
                                    name="time"
                                    value={callbackFormik?.values?.time}
                                    onChange={(e: any) => {
                                        callbackFormik?.setFieldValue(
                                            'time',
                                            e,
                                        );
                                    }}
                                    as={CustomFormControl}
                                    className="form-control"
                                />

                                <label>
                                    {translate(
                                        'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.TIME.TITLE',
                                    )}
                                    <span className="gl-form-asterisk"></span>
                                </label>
                                {callbackFormik?.errors?.time &&
                                    callbackFormik?.touched?.time && (
                                        <p className="text-danger">
                                            {callbackFormik?.errors?.time}
                                        </p>
                                    )}
                            </div>
                        </div>
                        <div className={styles.formGroup}>
                            <Form.Group
                                className={styles.formgroupBox}
                                controlId="exampleForm.ControlInput1"
                            >
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder={translate(
                                            'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.FIRST_NAME.TITLE',
                                        )}
                                        name="firstName"
                                        value={
                                            callbackFormik?.values?.firstName
                                        }
                                        onChange={handleChange}
                                    />
                                    {callbackFormik?.errors?.firstName &&
                                        callbackFormik?.touched?.firstName && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.firstName?.toString()}
                                            </p>
                                        )}
                                </div>
                                <div>
                                    <Form.Control
                                        type="text"
                                        placeholder={translate(
                                            'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.LAST_NAME.TITLE',
                                        )}
                                        name="lastName"
                                        value={callbackFormik?.values?.lastName}
                                        onChange={handleChange}
                                    />
                                    {callbackFormik?.errors?.lastName &&
                                        callbackFormik?.touched?.lastName && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.lastName?.toString()}
                                            </p>
                                        )}
                                </div>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group
                                className={styles.formgroupBox}
                                controlId="phone"
                            >
                                <div className={styles.lastFrom}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label={translate(
                                                'COMMON_COMPONENTS.REQUEST_CALLBACK.FIELDS.PHONE.TITLE',
                                            )}
                                        />
                                        <CustomPhoneInput
                                            country={'in'}
                                            placeholder=""
                                            value={
                                                callbackFormik?.values?.phone
                                            }
                                            onChange={(
                                                phone: any,
                                                country: any,
                                            ) => {
                                                setCountryCode(
                                                    country?.dialCode,
                                                );
                                                callbackFormik?.setFieldValue(
                                                    'phone',
                                                    phone,
                                                );
                                            }}
                                        />
                                    </Form.Group>
                                    {callbackFormik?.errors?.phone &&
                                        callbackFormik?.touched?.phone && (
                                            <p className="text-danger">
                                                {callbackFormik?.errors?.phone?.toString()}
                                            </p>
                                        )}
                                </div>
                            </Form.Group>
                        </div>
                        <div className={styles.formbottomBtn}>
                            <button>
                                {translate(
                                    'COMMON_COMPONENTS.REQUEST_CALLBACK.CONTINUE_BUTTON',
                                )}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default RequestCallbackFormModal;
