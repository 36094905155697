import { Fragment } from 'react';
import loader from '../../assets/images/loader.gif';

interface ICustomProps {
    style?: object;
}
const CustomLoader = ( { style = {} }: ICustomProps) => {
    return (
        <Fragment>
            <div className="d-flex justify-content-center">
                <img src={loader} alt="loader" style={{ width: '14%' ,...style}} />
            </div>
        </Fragment>
    );
};

export default CustomLoader;
