import toast from 'react-hot-toast';
import * as POPUP_ACTIONS from '../Actions/PopupActions';

/**
 * Interface for the state of the popup reducer.
 */
export interface IPopupState {
    // Object containing the state of the needHelp popup.
    needHelp: {
        // Boolean indicating whether the needHelp popup is shown.
        show: boolean;
        // String containing the id of the needHelp popup.
        id: string;
    };
    // Boolean indicating whether the getHelp popup is shown.
    getHelp: boolean;
    // Object containing the state of the talkToExpert popup.
    talkToExpert: {
        // Boolean indicating whether the talkToExpert popup is shown.
        show: boolean;
        // Boolean indicating whether the talkToExpert popup requires a special requirement.
        specialRequirement: boolean;
    };
    // Object containing the state of the requestCallback popup.
    requestCallback: {
        // Boolean indicating whether the requestCallback popup is shown.
        show: boolean;
        // Boolean indicating whether the requestCallback popup was triggered by the talkToExpert popup.
        talkToExpert: boolean;
    };
    // Boolean indicating whether the showMoreDetails popup is shown.
    showMoreDetails: boolean;
    // Boolean indicating whether the understandWhy popup is shown.
    understandWhy: boolean;

    donTHaveDetails: boolean;
}

/**
 * Initial state of the popup reducer.
 */
const initialState: IPopupState = {
    needHelp: {
        show: false,
        id: '',
    },
    getHelp: false,
    talkToExpert: {
        show: false,
        specialRequirement: false,
    },
    requestCallback: {
        show: false,
        talkToExpert: false,
    },
    showMoreDetails: false,
    understandWhy: false,
    donTHaveDetails: false,
};

const closeNeedHelpPopup = () => {
    const getId = localStorage.getItem('idleId');
    if (getId) {
        localStorage.setItem('idleId', '');
        toast.dismiss(getId);
    }
};
/**
 * Reducer for the popup state.
 * @param state - The current state of the popup reducer.
 * @param action - The action to be performed.
 * @returns The new state of the popup reducer.
 */
export const PopupReducer = (
    state: IPopupState = initialState,
    action: any,
): IPopupState => {
    switch (action.type) {
        case POPUP_ACTIONS.OPEN_POPUP_NEED_HELP:
            // Open the needHelp popup and set the id.
            return {
                ...state,
                needHelp: {
                    show: true,
                    id: action.payload,
                },
            };

        case POPUP_ACTIONS.CLOSE_POPUP_NEED_HELP:
            // Close the needHelp popup and clear the id.
            return {
                ...state,
                needHelp: {
                    show: false,
                    id: '',
                },
            };
        case POPUP_ACTIONS.OPEN_POPUP_GET_HELP:
            // Open the getHelp popup.

            closeNeedHelpPopup();
            return {
                ...state,
                getHelp: true,
            };

        case POPUP_ACTIONS.CLOSE_POPUP_GET_HELP:
            // Close the getHelp popup.
            closeNeedHelpPopup();
            return {
                ...state,
                getHelp: false,
            };

        case POPUP_ACTIONS.OPEN_POPUP_TALK_TO_EXPERT:
            // Open the talkToExpert popup.
            closeNeedHelpPopup();
            return {
                ...state,
                talkToExpert: {
                    show: true,
                    specialRequirement: action.payload,
                },
            };

        case POPUP_ACTIONS.CLOSE_POPUP_TALK_TO_EXPERT:
            // Close the talkToExpert popup.
            closeNeedHelpPopup();
            return {
                ...state,
                talkToExpert: {
                    show: false,
                    specialRequirement: false,
                },
            };

        case POPUP_ACTIONS.OPEN_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK:
            // Open the talkToExpertRequestCallback popup.
            closeNeedHelpPopup();
            return {
                ...state,
                talkToExpert: {
                    ...state.talkToExpert,
                    show: false,
                },
                requestCallback: {
                    show: true,
                    talkToExpert: true,
                },
            };

        case POPUP_ACTIONS.OPEN_POPUP_REQUEST_CALLBACK:
            // Open the requestCallback popup.
            closeNeedHelpPopup();
            return {
                ...state,
                requestCallback: {
                    show: true,
                    talkToExpert: action.payload,
                },
            };

        case POPUP_ACTIONS.CLOSE_POPUP_REQUEST_CALLBACK:
            // Close the requestCallback popup and clear the talkToExpert popup.
            closeNeedHelpPopup();
            return {
                ...state,
                requestCallback: {
                    show: false, // Set the show property to false.
                    talkToExpert: false, // Set the talkToExpert property to false.
                },
            };

        case POPUP_ACTIONS.CLOSE_POPUP_TALK_TO_EXPERT_REQUEST_CALLBACK:
            // Close the talkToExpertRequestCallback popup.
            closeNeedHelpPopup();
            return {
                ...state,
                talkToExpert: {
                    ...state.talkToExpert,
                    show: action.payload,
                },
                requestCallback: {
                    show: false,
                    talkToExpert: false,
                },
            };

        case POPUP_ACTIONS.OPEN_POPUP_SHOW_MORE_DETAILS:
            // Open the showMoreDetails popup.
            closeNeedHelpPopup();
            return {
                ...state,
                showMoreDetails: true,
            };

        case POPUP_ACTIONS.CLOSE_POPUP_SHOW_MORE_DETAILS:
            // Close the showMoreDetails popup.
            closeNeedHelpPopup();
            return {
                ...state,
                showMoreDetails: false,
            };

        case POPUP_ACTIONS.OPEN_POPUP_UNDERSTAND_WHY:
            // Open the understandWhy popup.
            closeNeedHelpPopup();
            return {
                ...state,
                understandWhy: true,
            };

        case POPUP_ACTIONS.CLOSE_POPUP_UNDERSTAND_WHY:
            // Close the understandWhy popup.
            closeNeedHelpPopup();
            return {
                ...state,
                understandWhy: false,
            };

        case POPUP_ACTIONS.OPEN_POPUP_DO_NOT_HAVE_THIS_DETAILS:
            // open do not have details popup
            closeNeedHelpPopup();
            return {
                ...state,
                donTHaveDetails: true,
            }

        case POPUP_ACTIONS.CLOSE_POPUP_DO_NOT_HAVE_THIS_DETAILS:
            // close do not have details popup
            closeNeedHelpPopup();
            return {
                ...state,
                donTHaveDetails: false,
            }

        default:
            // Return the current state if no action is matched.
            return state;
    }
};

export default PopupReducer;
