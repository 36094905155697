// stepReducer.ts
import { MIN_FIRE_INSURANCE_VALUE } from '../../constants/main';
import {
    StepActionTypes,
    GO_TO_NEXT_STEP,
    GO_TO_PREV_STEP,
    SET_STEP,
    RESET_STEP,
    OPEN_GET_HELP_MODAL,
    CLOSE_GET_HELP_MODAL,
    TAB_COMPLETED,
    DESIGN_DATA,
    MERCHANT_QUOTATION_REQUEST,
    GET_MERCHANT_STEP_REQUEST,
    GET_MERCHANT_STEP_ERROR,
    GET_MERCHANT_STEP_SUCCESS,
    SET_MERCHANT_STEP_REQUEST,
    SET_MERCHANT_STEP_ERROR,
    SET_MERCHANT_STEP_SUCCESS,
    EXIT_CALLBACK_MODAL,
    MERCHANT_QUOTATION_ERROR,
    MERCHANT_QUOTATION_SUCCESS,
    UPDATE_MERCHANT_QUOTATION_SUCCESS,
    UPDATE_MERCHANT_QUOTATION_ERROR,
    UPDATE_MERCHANT_QUOTATION_REQUEST,
    USER_DETAILS,
    SAVE_ONBOARDING_FORM,
    SEND_OTP_PHONE_LOGIN_SUCCESS,
    SEND_OTP_PHONE_LOGIN_REQUEST,
    SEND_OTP_PHONE_LOGIN_ERROR,
    SEND_RESEND_OTP_PHONE_LOGIN_REQUEST,
    SEND_RESEND_OTP_PHONE_LOGIN_SUCCESS,
    SEND_RESEND_OTP_PHONE_LOGIN_ERROR,
    USER_LOGOUT_RESET_SME_DETAILS,
    VERIFY_OTP_PHONE_LOGIN_REQUEST,
    VERIFY_OTP_PHONE_LOGIN_SUCCESS,
    VERIFY_OTP_PHONE_LOGIN_ERROR,
    CHANGE_INSURANCE_TYPE,
    SPECIAL_REQUIREMENT,
    UPDATE_QUOTATION_PURCHASE_METADATA,
    RESET_MERCHANT_STEP_DETAILS,
    EDIT_OTP_PHONE_NUMBER,
    EDIT_OTP_PHONE_NUMBER_CLEAR,
} from '../Actions/SMEActions';

export interface StepState {
    currentStep: number;
    maxStep: number;
    getHelpModal: boolean;
    completedTabs: number;
    design: { [key: string]: any };
    quotation: { [key: string]: any };
    quotationDetails: any;
    loading: boolean;
    exitCallbackModal: boolean;
    specialRequirement: boolean;
    userDetails: {};
    onBoardingFormData: { [key: string]: any };
    insuranceType: string;
    userLoginOtpData: {
        loading: boolean;
        mobile?: string;
        request_id?: string;
        resendOTP: {
            loading: boolean;
        };
        isNewUser?: boolean;
        editMobileNumber?: boolean;
        whatsapp?: boolean;
    };
    firstLoad: boolean;
}

const initialState: StepState = {
    currentStep: 1,
    maxStep: 16,
    getHelpModal: false,
    completedTabs: 0,
    design: {},
    quotation: {
        natureOfBusiness: '',
        categoryOfBusiness: '',
        insurers: ['iciciLombard'],
        correlationId: null,
        policyStartDate: '',
        typeOfPolicy: 'New Business',
        locationBasedRiskAndCoverPackages: [
            {
                locationId: 1,
                occupancyType: null,
                riskLocation: {
                    pincode: null,
                },
                buildingRiskProfile: {
                    constructionDetails: {
                        puccaOrKutcha: '',
                        typeOfClass: 'Class A',
                    },
                    hasBasementRisk: false,
                },
                risks: [
                    {
                        riskName: 'Building Including Plinth & Foundation',
                        sumInsured: MIN_FIRE_INSURANCE_VALUE,
                    },
                ],
                standardCovers: [
                    {
                        coverName: 'STFI Cover',
                        required: true,
                    },
                    {
                        coverName: 'RSMD Cover',
                        required: true,
                    },
                    {
                        coverName: 'Earthquake Cover',
                        required: true,
                    },
                    {
                        coverName: 'Terrorism Cover',
                        required: false,
                    },
                ],
            },
        ],
        purchaseMetadata: {},
    },
    quotationDetails: null,
    loading: false,
    exitCallbackModal: false,
    specialRequirement: false,
    userDetails: {},
    insuranceType: 'fire',
    onBoardingFormData: {
        personalDetails: {},
        companyDetails: {},
    },
    userLoginOtpData: { loading: false, resendOTP: { loading: false } },
    firstLoad: true,
};

const SMEReducer = (
    state: StepState = initialState,
    action: StepActionTypes | any,
): StepState => {
    switch (action.type) {
        case GO_TO_NEXT_STEP:
            return {
                ...state,
                currentStep:
                    state.currentStep + 1 <= state.maxStep
                        ? state.currentStep + 1
                        : state.currentStep,
            };
        case GO_TO_PREV_STEP:
            let newStep = state.currentStep;

            if (state.currentStep - 1 > 0) {
                if (
                    state.insuranceType === 'stock' &&
                    (state.currentStep === 6 || state.currentStep === 9)
                ) {
                    if (state.currentStep === 6) {
                        newStep = state.currentStep - 2;
                    } else if (state.currentStep === 9) {
                        newStep = state.currentStep - 3;
                    }
                } else if (state.currentStep === 3) {
                    newStep = state.currentStep - 2;
                } else {
                    newStep = state.currentStep - 1;
                }
            } else {
                newStep = state.currentStep;
            }

            return {
                ...state,
                currentStep: newStep,
            };

        case RESET_MERCHANT_STEP_DETAILS:
            return initialState;
        case SET_STEP:
            return {
                ...state,
                currentStep:
                    action.payload >= 1 && action.payload <= state.maxStep
                        ? action.payload
                        : state.currentStep,
            };
        case RESET_STEP:
            return {
                ...state,
                currentStep: 1,
            };
        case OPEN_GET_HELP_MODAL:
            return {
                ...state,
                getHelpModal: true,
            };
        case CLOSE_GET_HELP_MODAL:
            return {
                ...state,
                getHelpModal: false,
            };
        case TAB_COMPLETED:
            return {
                ...state,
                completedTabs: action.payload,
            };
        case DESIGN_DATA:
            return {
                ...state,
                design: { ...state.design, ...action.payload },
            };

        case GET_MERCHANT_STEP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_MERCHANT_STEP_ERROR:
            return {
                ...state,
                loading: false,
                firstLoad: false,
            };

        case UPDATE_QUOTATION_PURCHASE_METADATA:
            return {
                ...state,
                quotation: {
                    ...state.quotation,
                    purchaseMetadata: action.payload,
                },
            };

        case GET_MERCHANT_STEP_SUCCESS:
            if (action.payload.insuranceType) {
                return {
                    ...state,
                    loading: false,
                    quotation: action.payload.requestMetadata,
                    quotationDetails: action.payload.responseMetadata,
                    currentStep:
                        action.payload.insuranceType === 'stock'
                            ? action.payload.stepNumber === 4
                                ? action.payload.stepNumber + 2
                                : action.payload.stepNumber === 6
                                    ? action.payload.stepNumber + 3
                                    : action.payload.stepNumber + 1
                            : action.payload.stepNumber + 1,
                    insuranceType: action.payload.insuranceType,
                    firstLoad: false,
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    quotation: action.payload.requestMetadata,
                    quotationDetails: action.payload.responseMetadata,
                    currentStep: action.payload.stepNumber + 1,
                    firstLoad: false,
                };
            }

        case SET_MERCHANT_STEP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SET_MERCHANT_STEP_ERROR:
            return {
                ...state,
                loading: false,
            };

        case SET_MERCHANT_STEP_SUCCESS:
            if (action.payload.insuranceType) {
                return {
                    ...state,
                    loading: false,
                    quotation: action.payload.requestMetadata,
                    quotationDetails: action.payload.responseMetadata,
                    currentStep:
                        action.payload.insuranceType === 'stock'
                            ? action.payload.stepNumber === 4
                                ? action.payload.stepNumber + 2
                                : action.payload.stepNumber === 6
                                    ? action.payload.stepNumber + 3
                                    : action.payload.stepNumber + 1
                            : action.payload.stepNumber + 1,
                    insuranceType: action.payload.insuranceType,
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    quotation: action.payload.requestMetadata,
                    quotationDetails: action.payload.responseMetadata,
                    currentStep: action.payload.stepNumber + 1,
                };
            }
        case EXIT_CALLBACK_MODAL:
            return {
                ...state,
                exitCallbackModal: !state.exitCallbackModal,
                specialRequirement: false,
            };

        case SPECIAL_REQUIREMENT:
            return {
                ...state,
                specialRequirement: true,
                exitCallbackModal: true,
            };

        case MERCHANT_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case MERCHANT_QUOTATION_ERROR:
            return {
                ...state,
                loading: false,
            };

        case MERCHANT_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationDetails: action.payload,
            };

        case UPDATE_MERCHANT_QUOTATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_MERCHANT_QUOTATION_ERROR:
            return {
                ...state,
                loading: false,
            };

        case UPDATE_MERCHANT_QUOTATION_SUCCESS:
            return {
                ...state,
                loading: false,
                quotationDetails: action.payload,
            };

        case USER_DETAILS:
            return {
                ...state,
                design: { ...state.userDetails, ...action.payload },
            };

        case SAVE_ONBOARDING_FORM:
            return {
                ...state,
                onBoardingFormData: { ...action.payload },
            };

        case VERIFY_OTP_PHONE_LOGIN_REQUEST:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    loading: true,
                },
            };

        case VERIFY_OTP_PHONE_LOGIN_SUCCESS:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    loading: false,
                },
            };

        case VERIFY_OTP_PHONE_LOGIN_ERROR:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    loading: false,
                },
            };

        case SEND_OTP_PHONE_LOGIN_REQUEST:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    loading: true,
                    mobile: action.payload.mobile,
                    whatsapp: action.payload.whatsapp
                },
            };

        case SEND_OTP_PHONE_LOGIN_SUCCESS:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    loading: false,
                    request_id: action.payload.request_id,
                    isNewUser: action.payload.isNewUser,
                },
            };

        case EDIT_OTP_PHONE_NUMBER:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    request_id: undefined,
                    editMobileNumber: true,
                },
            };

        case EDIT_OTP_PHONE_NUMBER_CLEAR:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    editMobileNumber: false,
                },
            };

        case SEND_OTP_PHONE_LOGIN_ERROR:
            return {
                ...state,
                userLoginOtpData: { ...state.userLoginOtpData, loading: false },
            };

        case SEND_RESEND_OTP_PHONE_LOGIN_REQUEST:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    resendOTP: { loading: true },
                },
            };

        case SEND_RESEND_OTP_PHONE_LOGIN_SUCCESS:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    resendOTP: { loading: false },
                },
            };

        case SEND_RESEND_OTP_PHONE_LOGIN_ERROR:
            return {
                ...state,
                userLoginOtpData: {
                    ...state.userLoginOtpData,
                    resendOTP: { loading: false },
                },
            };

        case USER_LOGOUT_RESET_SME_DETAILS:
            return {
                ...initialState,
            };

        case CHANGE_INSURANCE_TYPE:
            return {
                ...state,
                insuranceType: action.payload,
            };

        default:
            return state;
    }
};

export default SMEReducer;
