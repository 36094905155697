import { CHANGE_LANGUAGE } from "../Actions/PreferenceActions";

export enum ELanguagesOptions {
    english = 'english',
    hindi = 'hindi'
}

export interface IPreferenceState {
    language: ELanguagesOptions;
}

const initialState : IPreferenceState = {
    language: ELanguagesOptions.english
};

const PreferenceReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:

            localStorage.setItem('language', action.payload)
            return {
                ...state,
                language: action.payload
            };
        default:
            return state;
    }
};

export default PreferenceReducer