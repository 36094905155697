import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';
import modalLogo from '../../../assets/images/modal-logo.png';
import icontroke from '../../../assets/images/Icon-troke.png';
import OtpInput from 'react-otp-input';
import CustomPhoneInput from '../../../common/PhoneInput';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { phonesRegx } from '../../../Helper/commonFunction';
import { PHONE } from '../../../constants/main';
import CustomLabel from '../../../common/CustomLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearEditPhoneNumber,
    editPhoneNumber,
    resendOtpPhoneLogin,
    sendOtpPhoneLogin,
    verifyOtpPhoneLogin,
} from '../../../Redux/Actions/SMEActions';
import { IReduxState } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { VerifyPhoneLogin } from '../../../Redux/Actions/AuthActions';
import { useTranslation } from 'react-i18next';
import { PencilSquare, XLg } from 'react-bootstrap-icons';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';

interface SMELoginProps {
    showLoginModal: boolean;
    setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
    navigationAllowed?: boolean;
}

const SMELoginResendSection = () => {
    const dispatch = useDispatch();

    const { t: translate } = useTranslation();

    const [seconds, setSeconds] = useState(50);

    const { userLoginOtpData } = useSelector(
        (state: IReduxState) => state.SMEDetails,
    );

    const handleResendOTPClick = () => {
        setSeconds(50);
        dispatch(
            resendOtpPhoneLogin({ mobile: userLoginOtpData?.mobile ?? '' }),
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <div className={styles.inputText}>
            <p className={styles.textinputBox}>
                <span className={styles.textInput}>
                    {translate(
                        'COMMON_COMPONENTS.LOGIN_POPUP.STEP_2.NOT_RECEIVE_OTP',
                    )}
                </span>{' '}
                <span
                    className={
                        seconds === 0
                            ? styles.resendOTPAllowed
                            : styles.resendOTPBlocked
                    }
                    onClick={handleResendOTPClick}
                >
                    {translate(
                        'COMMON_COMPONENTS.LOGIN_POPUP.STEP_2.RESEND_OTP',
                    )}{' '}
                </span>
                <span className={styles.textInput}>
                    (00:
                    {seconds < 10 ? `0${seconds}` : seconds})
                </span>
            </p>
        </div>
    );
};

const SMELogin: React.FC<SMELoginProps> = ({
    showLoginModal,
    setShowLoginModal,
    navigationAllowed = true,
}) => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { t: translate } = useTranslation();

    const { userLoginOtpData, insuranceType } = useSelector(
        (state: IReduxState) => state.SMEDetails,
    );

    const { editMobileNumber = false } = userLoginOtpData;

    // change to 0 , 1 , 2
    const [step, setStep] = useState(0);
    const [signUpData, setSignUpData] = useState<{
        first_name: string;
        last_name: string;
        email: string;
    } | null>(null);

    const [mobileNumber, setMobileNumber] = useState('');
    const [OTP, setOTP] = useState('');
    const [submitCount, setSubmitCount] = useState(0);

    const [countryCode, setCountryCode] = useState<string>('91');

    const signInValidationSchema = yup.object().shape({
        mobile: yup
            .string()
            .required('Please Enter Your Phone Number')
            .matches(phonesRegx[countryCode], PHONE),
        whatsapp: yup.boolean()
    });

    const signInFormik = useFormik({
        initialValues: {
            mobile: '',
            whatsapp: false,
        },
        validationSchema: signInValidationSchema,
        onSubmit: async (values) => {
            dispatch(sendOtpPhoneLogin(values));
        },
    });

    const signUpValiationSchema = yup.object().shape({
        first_name: yup.string().required('Please Enter First Name'),
        last_name: yup.string().required('Please Enter Last Name'),
        email: yup.string().email().required('Please Enter Email'),
    });

    const signUpFormik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        validationSchema: signUpValiationSchema,
        onSubmit: async (values) => {
            setSignUpData(values);
            dispatch(clearEditPhoneNumber());
            setStep(1);
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        signUpFormik.setFieldValue(name, value);
    };

    const handleOtpVerification = () => {
        const regex = /^\d{4}$/;

        if (regex.test(OTP)) {
            const mainPayload = {
                otp: OTP,
                mobile: userLoginOtpData?.mobile ?? '',
                requestId: userLoginOtpData?.request_id ?? '',
                whatsapp: userLoginOtpData?.whatsapp ?? false,
                ...(userLoginOtpData?.isNewUser ? signUpData : {}),
            };

            const verifyPhoneLoginPayload = {
                navigate,
                shouldNavigate: navigationAllowed,
                insuranceType: insuranceType,
            };

            const dispatchPayload = {
                dispatch,
                actionTrigger: VerifyPhoneLogin,
            };

            const finalPayload = {
                mainPayload,
                verifyPhoneLoginPayload,
                dispatchPayload,
            };

            dispatch(verifyOtpPhoneLogin(finalPayload));
        } else {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Invalid OTP format.',
                    severity: 'danger',
                    show: true,
                },
            });
        }
    };

    const handlePhoneNumberEditClick = () => {
        dispatch(editPhoneNumber());
    };

    useEffect(() => {
        if (
            userLoginOtpData?.editMobileNumber &&
            !userLoginOtpData?.request_id
        ) {
            setStep(0);
        } else if (!userLoginOtpData.loading && userLoginOtpData?.request_id) {
            if (userLoginOtpData?.isNewUser) {
                if (signUpData && !editMobileNumber) {
                    setStep(1);
                } else {
                    setStep(2);
                }
            } else {
                setStep(1);
            }
        }
    }, [userLoginOtpData]);

    useEffect(() => {
        if (
            step === 1 &&
            OTP.length === 4 &&
            !userLoginOtpData.loading &&
            userLoginOtpData?.request_id
        ) {
            handleOtpVerification();
        }
    }, [OTP]);

    // uncomment at the time of pushing
    // useEffect(() => {
    //     setStep(0);
    // }, [showLoginModal]);

    return (
        <Modal
            show={showLoginModal}
            onHide={() => setShowLoginModal(false)}
            centered
            className={styles.loginPopUp}
        >
            <Button className={styles.closeIcon} onClick={() => setShowLoginModal(false)}>
                <XLg />
            </Button>
            <div className={styles.login_modal}>
                {step !== 2 && (
                    <div className={styles.modelTop}>
                        <div className={styles.modalBoxLogo}>
                            <img src={modalLogo} alt="modalLogo" />
                        </div>
                    </div>
                )}
                {step === 0 && (
                    <div className={styles.formData}>
                        <div className={styles.modalHeading}>
                            <h2>
                                {translate(
                                    'COMMON_COMPONENTS.LOGIN_POPUP.STEP_1.TITLE',
                                )}
                            </h2>
                        </div>
                        <div className={styles.form_main}>
                            {/* <input type="number" className={styles.formControl} id="exampleNumberInput" placeholder="+91 Enter a number"></input> */}
                            <Form onSubmit={signInFormik.handleSubmit} className={styles.step_one}>
                                <Form.Group className="form-group">
                                    <CustomLabel
                                        label={translate(
                                            'COMMON_COMPONENTS.LOGIN_POPUP.STEP_1.PHONE_NUMBER',
                                        )}
                                    />
                                    <CustomPhoneInput
                                        country={'in'}
                                        placeholder=""
                                        value={signInFormik.values.mobile}
                                        onChange={(
                                            phone: any,
                                            country: any,
                                        ) => {
                                            setCountryCode(country?.dialCode);
                                            signInFormik.setFieldValue(
                                                'mobile',
                                                phone,
                                            );
                                        }}
                                        onKeyDown={(event: any) => {
                                            if (event.keyCode === 13) {
                                                signInFormik.submitForm();
                                            }
                                        }}
                                    />
                                    {signInFormik.errors.mobile &&
                                        signInFormik.touched.mobile && (
                                            <span
                                                className={
                                                    styles.modaltextError
                                                }
                                            >
                                                {signInFormik.errors.mobile}
                                            </span>
                                        )}
                                </Form.Group>
                                <div className={styles.inputText}>
                                    <p>
                                        <span>
                                            <img
                                                src={icontroke}
                                                alt="icontroke"
                                            />{' '}
                                            {translate(
                                                'COMMON_COMPONENTS.LOGIN_POPUP.COMMON.SPAM_MESSAGE_GREEN',
                                            )}
                                        </span>{' '}
                                        {translate(
                                            'COMMON_COMPONENTS.LOGIN_POPUP.COMMON.SPAM_MESSAGE_BLACK',
                                        )}
                                    </p>
                                </div>
                                <div className={styles.checkboxContainer}>
                                    <label>
                                        <input type="checkbox" id="whatsapp" checked={signInFormik.values.whatsapp}
                                            onChange={(e: any) => {
                                                signInFormik.setFieldValue("whatsapp", e.target.checked)
                                            }}
                                        />{' '}
                                        <p>
                                            {translate('COMMON_COMPONENTS.LOGIN_POPUP.COMMON.CHECKBOX_LABEL')}
                                        </p>

                                    </label>
                                </div>
                                <div className={styles.modalButton}>
                                    <button
                                        type="submit"
                                        disabled={userLoginOtpData?.loading}
                                    >
                                        <span>
                                            {userLoginOtpData?.loading ? (
                                                <Spinner
                                                    animation="border"
                                                    style={{
                                                        width: 23,
                                                        height: 23,
                                                    }}
                                                />
                                            ) : (
                                                translate(
                                                    'COMMON_COMPONENTS.LOGIN_POPUP.COMMON.CONTINUE_BUTTON',
                                                )
                                            )}
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}

                {step === 1 && (
                    <div className={styles.formData}>
                        <div className={styles.modalHeading}>
                            <h2>
                                {translate(
                                    'COMMON_COMPONENTS.LOGIN_POPUP.STEP_2.TITLE',
                                )}
                            </h2>
                            <h3>
                                {translate(
                                    'COMMON_COMPONENTS.LOGIN_POPUP.STEP_2.OTP',
                                )}{' '}
                                {userLoginOtpData?.mobile
                                    ? '+' + userLoginOtpData?.mobile
                                    : ''}
                                <PencilSquare
                                    onClick={handlePhoneNumberEditClick}
                                />
                            </h3>
                        </div>
                        <div className={styles.form_main_two}>
                            {/* <input type="number" className={styles.formControl} id="exampleNumberInput" placeholder="+91 Enter a number"></input> */}
                            <Form>
                                <InputGroup className={styles.otpWrp}>
                                    <OtpInput
                                        inputStyle={styles.otpInput}
                                        value={OTP}
                                        onChange={setOTP}
                                        numInputs={4}
                                        renderSeparator={<span> </span>}
                                        renderInput={(props) => (
                                            <input {...props} />
                                        )}
                                    />
                                </InputGroup>
                            </Form>
                            <SMELoginResendSection />
                            <div className={styles.modalButton}>
                                <button
                                    onClick={handleOtpVerification}
                                    disabled={userLoginOtpData?.loading}
                                >
                                    {userLoginOtpData?.loading ? (
                                        <Spinner
                                            animation="border"
                                            style={{
                                                width: 23,
                                                height: 23,
                                            }}
                                        />
                                    ) : (
                                        translate(
                                            'COMMON_COMPONENTS.LOGIN_POPUP.STEP_2.VERIFY_BUTTON',
                                        )
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div
                        className={`${styles.formData} ${styles.lastScreenForm}`}
                    >
                        <div className={styles.lastHeading}>
                            <h4>
                                {translate(
                                    'COMMON_COMPONENTS.LOGIN_POPUP.STEP_3.THANK_YOU_MESSAGE',
                                )}
                            </h4>
                            <h5>
                                {translate(
                                    'COMMON_COMPONENTS.LOGIN_POPUP.STEP_3.TITLE',
                                )}
                            </h5>
                        </div>
                        <div className={styles.form_main_three}>
                            {/* <input type="number" className={styles.formControl} id="exampleNumberInput" placeholder="+91 Enter a number"></input> */}
                            <Form onSubmit={signUpFormik.handleSubmit}>
                                <div className={styles.maininputBox}>
                                    <InputGroup className={styles.lastPopop}>
                                        <Form.Control
                                            placeholder={translate(
                                                'COMMON_COMPONENTS.LOGIN_POPUP.STEP_3.FIRST_NAME',
                                            )}
                                            type="text"
                                            name="first_name"
                                            value={
                                                signUpFormik.values.first_name
                                            }
                                            onChange={handleChange}
                                        />
                                        {signUpFormik.errors.first_name &&
                                            signUpFormik.touched.first_name && (
                                                <span
                                                    className={
                                                        styles.modaltextError
                                                    }
                                                >
                                                    {
                                                        signUpFormik.errors
                                                            .first_name
                                                    }
                                                </span>
                                            )}
                                    </InputGroup>
                                    <InputGroup className={styles.lastPopop}>
                                        <Form.Control
                                            placeholder={translate(
                                                'COMMON_COMPONENTS.LOGIN_POPUP.STEP_3.LAST_NAME',
                                            )}
                                            type="text"
                                            name="last_name"
                                            value={
                                                signUpFormik.values.last_name
                                            }
                                            onChange={handleChange}
                                        />
                                        {signUpFormik.errors.last_name &&
                                            signUpFormik.touched.last_name && (
                                                <span
                                                    className={
                                                        styles.modaltextError
                                                    }
                                                >
                                                    {
                                                        signUpFormik.errors
                                                            .last_name
                                                    }
                                                </span>
                                            )}
                                    </InputGroup>
                                </div>
                                <div className={styles.emailInput}>
                                    <InputGroup className={styles.lastPopop}>
                                        <Form.Control
                                            placeholder={translate(
                                                'COMMON_COMPONENTS.LOGIN_POPUP.STEP_3.EMAIL_ADDRESS',
                                            )}
                                            type="text"
                                            name="email"
                                            value={signUpFormik.values.email}
                                            onChange={handleChange}
                                        />
                                        {signUpFormik.errors.email &&
                                            signUpFormik.touched.email && (
                                                <span
                                                    className={
                                                        styles.modaltextError
                                                    }
                                                >
                                                    {signUpFormik.errors.email}
                                                </span>
                                            )}
                                    </InputGroup>
                                </div>

                                <div className={styles.inputText}>
                                    <p>
                                        <span>
                                            <img
                                                src={icontroke}
                                                alt="icontroke"
                                            />{' '}
                                            {translate(
                                                'COMMON_COMPONENTS.LOGIN_POPUP.COMMON.SPAM_MESSAGE_GREEN',
                                            )}
                                        </span>{' '}
                                        {translate(
                                            'COMMON_COMPONENTS.LOGIN_POPUP.COMMON.SPAM_MESSAGE_BLACK',
                                        )}
                                    </p>
                                </div>
                                <div className={styles.modalButton}>
                                    <button type="submit">
                                        <span>
                                            {userLoginOtpData?.loading ? (
                                                <Spinner
                                                    animation="border"
                                                    style={{
                                                        width: 23,
                                                        height: 23,
                                                    }}
                                                />
                                            ) : (
                                                'Continue'
                                            )}
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default SMELogin;
