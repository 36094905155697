import React, { useEffect, useState } from 'react';
import {
    Accordion,
    Button,
    Col,
    Container,
    Form,
    Offcanvas,
    Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Ratio from 'react-bootstrap/Ratio';
import style from './style.module.scss';
import { Search, TelephoneInbound, XLg } from 'react-bootstrap-icons';
import stockInsurance from '../../assets/videos/Stock-Insurance.mp4'
import fireInsurance from '../../assets/videos/Fire-insurance-explainer.mp4'
import SampleVideo from '../../assets/videos/SampleVideo.mp4';
import RequestCallbackFormModal from '../RequestCallbackFormModal';
import { useDebounce } from '../../Helper/commonFunction';
import {
    closePopupGetHelp,
    openPopupRequestCallback,
} from '../../Redux/Actions/PopupActions';
import { IReduxState } from '../../utils/types';
import { useTranslation } from 'react-i18next';

interface SMEOffCanvasProps {
    show: boolean;
}

const SMEOffcanvas: React.FC<SMEOffCanvasProps> = ({ show }) => {
    /**
     * Retrieves the request callback state from the Redux store.
     *
     * @return {Object} The request callback state object.
     */
    const { requestCallback } = useSelector(
        (state: IReduxState) => state.Popup,
    );

    const { t: translate } = useTranslation();

    const VIDEODATA = [
        {
            title: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.1.TITLE',
            ),
            description: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.1.DESCRIPTION',
            ),
            src: 'https://covrzy-content.s3.ap-south-1.amazonaws.com/Fire-insurance-explainer.mp4',
            autoPlay: false,
        },
        {
            title: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.2.TITLE',
            ),
            description: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.2.DESCRIPTION',
            ),
            src: 'https://covrzy-content.s3.ap-south-1.amazonaws.com/Stock-Insurance.mp4',
            autoPlay: false,
        }
        // {
        //     title: translate(
        //         'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.3.TITLE',
        //     ),
        //     description: translate(
        //         'COMMON_COMPONENTS.GET_HELP_POPUP.VIDEO_DATA.3.DESCRIPTION',
        //     ),
        //     src: 'https://www.youtube-nocookie.com/embed/DrJQvG4XJvU?si=9La8tenOQeWNQIKw&amp;controls=0',
        // },
    ];

    const FAQS = [
        {
            question: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.1.QUESTION',
            ),
            answer: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.1.ANSWER.DESCRIPTION_1',
            ),
        },
        {
            question: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.2.QUESTION',
            ),
            answer: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.2.ANSWER.DESCRIPTION_1',
            ),
        },
        {
            question: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.QUESTION',
            ),
            answer: (
                <>
                    {translate(
                        'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.DESCRIPTION_1',
                    )}
                    <ul>
                        <li>
                            <strong>
                                {translate(
                                    'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_1.TITLE',
                                )}
                            </strong>{' '}
                            {translate(
                                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_1.CONTENT',
                            )}
                        </li>
                        <li>
                            <strong>
                                {translate(
                                    'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_2.TITLE',
                                )}
                            </strong>{' '}
                            {translate(
                                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_2.CONTENT',
                            )}
                        </li>
                        <li>
                            <strong>
                                {translate(
                                    'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_3.TITLE',
                                )}
                            </strong>{' '}
                            {translate(
                                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.3.ANSWER.POINTS_3.CONTENT',
                            )}
                        </li>
                    </ul>
                </>
            ),
        },
        {
            question: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.4.QUESTION',
            ),
            answer: translate(
                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_DATA.4.ANSWER.DESCRIPTION_1',
            ),
        },
    ];
    /**
     * Dispatches an action to open the request callback pop-up.
     */
    const dispatch = useDispatch();

    /**
     * Handles opening the request callback pop-up.
     * Dispatches the openPopupRequestCallback action.
     */
    const handleRequestCallbackOpen = () => {
        // Dispatch the openPopupRequestCallback action to open the request callback pop-up
        dispatch(openPopupRequestCallback());
    };
    const handleClose = () => {
        // Define your handleClose logic here
        dispatch(closePopupGetHelp());
    };

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<any[]>([...FAQS]);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const searchFAQs = () => {
            let results = [];

            if (debouncedSearchTerm) {
                results = FAQS.filter((item) => {
                    return item.question
                        .toLowerCase()
                        .includes(debouncedSearchTerm.toLowerCase());
                });
            } else {
                results = [...FAQS];
            }
            setSearchResults(results);
        };

        searchFAQs();
    }, [debouncedSearchTerm]);

    return (
        <div className={style.smeOffcanvasBox}>
            <Offcanvas
                className={style.smeOffcanvas}
                show={show && !requestCallback.show}
                onHide={handleClose}
                placement="bottom"
            >
                <Offcanvas.Body>
                    <Button
                        className={style.modelCloseBtn}
                        onClick={handleClose}
                    >
                        {' '}
                        <XLg />{' '}
                    </Button>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                {VIDEODATA.map((item, index) => (
                                    <div key={index} className={style.videoWrp}>
                                        <div className={style.videoBox}>
                                            <Ratio key="4x3">
                                                <video
                                                    width="560"
                                                    height="315"
                                                    src={item.src}
                                                    title={item.title}
                                                    controls
                                                    autoPlay={item.autoPlay}
                                                    muted={false}
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                            </Ratio>
                                        </div>
                                        <div className={style.textBox}>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                            <Col lg={6}>
                                <div className={style.questionsBox}>
                                    <div className={style.searchBox}>
                                        <Form.Control
                                            size="lg"
                                            type="text"
                                            placeholder={translate(
                                                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_SEARCH_PLACEHOLDER',
                                            )}
                                            onChange={handleChange}
                                            value={searchTerm}
                                        />
                                        <div className={style.searchIcon}>
                                            <Search />
                                        </div>
                                    </div>
                                    <div className={style.questionList}>
                                        <h3 className={style.accordianTitle}>
                                            {translate(
                                                'COMMON_COMPONENTS.GET_HELP_POPUP.FAQS_TITLE',
                                            )}
                                        </h3>
                                        <div>
                                            <Accordion defaultActiveKey="0">
                                                {searchResults.map(
                                                    (item, index) => (
                                                        <Accordion.Item
                                                            key={index}
                                                            eventKey={`${index}`}
                                                        >
                                                            <Accordion.Header>
                                                                {item.question}
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {item.answer}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ),
                                                )}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className={style.offcanvasFooter}>
                        <h3 className={style.offcanvasFooterTitle}>
                            <span>
                                {translate(
                                    'PAGE_COMPONENTS.BUY_INSURANCE.NEED_HELP_POPUP.TITLE',
                                )}{' '}
                            </span>
                            {translate(
                                'PAGE_COMPONENTS.BUY_INSURANCE.NEED_HELP_POPUP.DESCRIPTION',
                            )}
                        </h3>
                        <div className={style.buttonRight}>
                            <Button
                                className={style.btnText}
                                onClick={handleClose}
                            >
                                {translate(
                                    'PAGE_COMPONENTS.BUY_INSURANCE.NEED_HELP_POPUP.CLOSE',
                                )}
                            </Button>
                            <Button
                                className={style.requestCall}
                                onClick={handleRequestCallbackOpen}
                            >
                                {' '}
                                <TelephoneInbound />{' '}
                                {translate(
                                    'PAGE_COMPONENTS.BUY_INSURANCE.NEED_HELP_POPUP.BUTTON',
                                )}
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <RequestCallbackFormModal />
        </div>
    );
};

export default SMEOffcanvas;
